import React, { useState } from 'react';

//components
import Layout from '../components/layout';
import Title from '../components/title';
import SEO from '../components/seo';

//icons
import { FiPhoneCall, FiMail } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';

//styles
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as styles from '../styles/components/contact.module.scss';
import { navigate } from 'gatsby';

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name,
        email,
        number,
        message,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <>
      <Layout>
        <Title picture title='Contact Us' />
        <SEO title='Contact' />
        <Container className='my-5 py-5'>
          <Row>
            <Col md={4}>
              <h3 className={`${styles.title}`}>Write to us</h3>
              <Form
                className={styles.form}
                onSubmit={handleSubmit}
                name='contact'
                method='post'
                action='/success/'
                data-netlify='true'
                data-netlify-honeypot='bot-field'
              >
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    placeholder='Your Name'
                    aria-label='name'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Form.Text className='text-muted '>
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='number'>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type='tel'
                    placeholder='Telephone Number'
                    name='number'
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label>E-mail Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='E-mail Address'
                    name='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlTextarea1'
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as='textarea'
                    placeholder='Message'
                    rows={3}
                    name='message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>

                <Button className='secondary w-100' type='submit'>
                  Submit
                </Button>
              </Form>
            </Col>
            <Col md={8} className='mt-5'>
              <iframe
                src='https://maps.google.com/maps?q=tripfution&t=&z=15&ie=UTF8&iwloc=&output=embed'
                width='100%'
                height='80%'
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>

              <div className='mt-3'>
                <p>
                  <HiOutlineLocationMarker className='me-2 text-success' />
                  12/5, Spathodea Avenue, Colombo 5
                </p>
                <p>
                  <FiPhoneCall className='me-2 text-success' />
                  <a
                    href='tel:+94112506008'
                    className='text-decoration-none text-dark'
                  >
                    (+94) 112 506 008
                  </a>
                </p>
                <p>
                  <FiMail className='me-2 text-success' />
                  <a
                    href='mailto:info@tripfusiondmc.com'
                    className='text-decoration-none  text-dark'
                  >
                    info@tripfusiondmc.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Contact;
