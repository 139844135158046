import React from 'react';
import { Container } from 'react-bootstrap';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from '../styles/title.module.scss';

export default function Title({
  title,
  subtitle,
  picture,
  date,
  gatsbyPicture,
}) {
  return (
    <>
      {gatsbyPicture && (
        <div className={styles.gatsbyPicture}>
          <GatsbyImage
            image={gatsbyPicture.gatsbyImageData}
            alt='hero-img'
            className={styles.gatsbyPicture__img}
            objectFit='cover'
          />
          <div className={styles.gatsbyPicture__title}>
            <h1 className={`h1 display-1 mx-auto ${styles.title}`}>{title}</h1>
            <h2 className={`h2 display-1${styles.subtitle}`}>{subtitle}</h2>
            <h3 className={`h3 display-1${styles.date}`}>{date}</h3>
          </div>
        </div>
      )}
      {picture && (
        <Container
          fluid
          className={` ${styles.wrapper} ${picture && styles.picture}`}
        >
          <h1 className={`h1 display-1 mx-auto ${styles.title}`}>{title}</h1>
          <h2 className={`h2 display-1${styles.subtitle}`}>{subtitle}</h2>
          <h3 className={`h3 display-1${styles.date}`}>{date}</h3>
        </Container>
      )}
    </>
  );
}
